*{font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"!important;

}
.footer-widget li a{
  color: #99a1b4;
}
.news-list-post .post-txt p{
font-size: large!important;
}
.topsocial li a{
  color: #efefef;
}
.modal-content{
  border-radius: 0!important;
}
.carousel-arrow-left, .carousel-arrow-right {
  width: 24px;  /* Adjust the width */
  height: 24px; /* Adjust the height */
}

.carousel-arrow-left:before, .carousel-arrow-right:before {
  font-size: 12px; /* Adjust the arrow icon size */
}

.register-now-btn{
  background: #0707BD;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.register-now-btn:hover{
  background: #fff;
  color: #4c4e53;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.mainHeader,
.mainFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  /* background: #eee; */
}
.form-control{
  appearance: auto!important;
}
.mainContent {
  display: flex;
  /* padding: 20px 0; */
}
a{
text-decoration: none!important;
}
.mainRightScroll {
  /* flex: 1 0 auto; */
  /* // display: flex;
  // align-items: center;
  // justify-content: center; */
  /* height: 2000px; */
  margin-right: 10px;
  width: 100%;
  /* background: #ccc; */
  /* background: linear-gradient(to bottom, #eeeeee 0%,#B7EAE0 100%); */
}
.mainSide {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* // position: -webkit-sticky; */
  position: sticky;
  top: 0px;
  width: 40%;
  min-height: 70vh;
  height: fit-content;
  /* color: #fff; */
  /* background: #30b298; */
}
  
  /* .scrollable-column {
    flex: 1;
    overflow-y: auto;
    height: 150vh; 
    padding-left: 20px;
  } */


  /* Custom Scrollbar Styling for Webkit Browsers */
.scrollable-column::-webkit-scrollbar {
    width: 10px;  /* Change this to make the scrollbar thinner or thicker */
}

.scrollable-column::-webkit-scrollbar-track {
    background: #efefef;  /* Color of the scrollbar track */
    border-radius: 10px;  /* Round edges for the scrollbar track */
}

.scrollable-column::-webkit-scrollbar-thumb {
    background: #efefef;  /* Color of the scrollbar thumb */
    border-radius: 10px;  /* Round edges for the scrollbar thumb */
}

.scrollable-column::-webkit-scrollbar-thumb:hover {
    background: #efefef; 
    border-radius: 10px; 
}

/* .scrollable-column {
    scrollbar-width: thin;  
    scrollbar-color: #888 #f1f1f1;
    border-radius: 10px;  
} */

/* .scrollable-column {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border-radius: 10px;
} */


/* .dropdown-menu .show{
  border: none!important;

} */
 .nav-item a{
  text-decoration: none!important;
 }

 @media screen and (max-width: 768px) {
  .postContent {
  max-height: 90px;
  overflow: hidden;  /* Adds a bottom margin of 10px */
  }
}
@media screen and (min-width: 769px) and (max-width:1200px) {
  .postContent {
  max-height: 90px;
  overflow: hidden; 
  }
  .postImage{
    width: 100%;
  }
  .post-txt{
    width: 100%;
  }
  .news-list-post .post-thumb{
    width: 100%;
  }
  .news-list-post .post-txt {
    width: 100%;

  }
}

.postContent{
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}